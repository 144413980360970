import React from "react";
import { useRecoilValue } from "recoil";
import { siteDataState } from "../recoil/atoms";

const FooterLinks = () => {
  const { footer_links } = useRecoilValue(siteDataState);

  if (footer_links.length === 0) {
    return null;
  }

  return (
    <div className="">
      <div className="block items-center container py-2 mx-auto">
        <ul className="flex justify-center mt-2 space-x-4 flex-wrap">
          {footer_links.map((link) => (
            <li key={link.id}>
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="h-12"></div>
    </div>
  );
};

export default FooterLinks;
