import React, { Suspense, useState, useEffect } from "react";
import "./tailwind.output.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { siteDataState, loggedInState, cartState } from "./recoil/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CDN_URL } from "./config";
import API from "./services/API";
import NotFound from "./pages/NotFound";
import * as serviceWorker from "./serviceWorker";
import SocialFooter from "./components/SocialFooter";
import FooterLinks from "./components/FooterLinks";

const Home = React.lazy(() => import("./pages/Home"));
const Cart = React.lazy(() => import("./pages/Cart"));
const SearchProducts = React.lazy(() => import("./pages/SearchProducts"));
const OrderDetails = React.lazy(() => import("./pages/OrderDetails"));
const Orders = React.lazy(() => import("./pages/Orders"));
const Login = React.lazy(() => import("./pages/Login"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const Register = React.lazy(() => import("./pages/Register"));
const Navbar = React.lazy(() => import("./components/Navbar"));
const Footer = React.lazy(() => import("./components/Footer"));

const Site = () => {
  const [loading, setLoading] = useState(true);
  const [siteData, setSiteData] = useRecoilState(siteDataState);
  const [offline, setOffline] = useState(false);
  const setLoggedIn = useSetRecoilState(loggedInState);
  const setCart = useSetRecoilState(cartState);
  let match = useRouteMatch();
  let history = useHistory();

  useEffect(() => {
    API({
      url: "/user/get_site_data",
      method: "post",
    })
      .then((res) => {
        setLoading(false);
        return res;
      })
      .then((res) => {
        if (res.data.success === 1) {
          setSiteData({ ...siteData, ...res.data.data });
          setCart({ count: res.data.cartCount });
          setLoggedIn(res.data.loggedIn);
          // set theme color
          document.documentElement.style.setProperty(
            "--themecolor",
            res.data.data.brand_colortheme
          );

          // set favicon
          document.querySelector(
            "link[rel*='icon']"
          ).href = `${CDN_URL}images/${res.data.data.favicon}`;

          // set apple-touch-icon
          document.querySelector(
            "link[rel*='apple-touch-icon']"
          ).href = `${CDN_URL}images/${res.data.data.brand_logo}`;

          if (res.data.data.pwa_enabled) {
            // set manifest.json
            document.querySelector(
              "link[rel*='manifest']"
            ).href = `/${res.data.data.catalog_slug}/manifest.json`;

            // register service worker
            serviceWorker.register();
          }
        } else {
          history.push("/404");
        }
      })
      .catch((e) => {
        setLoading(false);
        setOffline(true);
        console.log(e);
      });
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!loading && offline) {
    return <div>There seems to be some trouble connecting to the server!</div>;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Navbar />
      <div className="pb-6">
        <Switch>
          <Route path={`${match.url}/login`}>
            <Login />
          </Route>
          <Route path={`${match.url}/forgot`}>
            <ForgotPassword />
          </Route>
          <Route path={`${match.url}/reset/:hash`}>
            <ResetPassword />
          </Route>
          <Route path={`${match.url}/register`}>
            <Register />
          </Route>
          <Route path={`${match.url}/cart`}>
            <Cart />
          </Route>
          <Route path={`${match.url}/search`}>
            <SearchProducts />
          </Route>
          <Route path={`${match.url}/orders/:orderid`}>
            <OrderDetails />
          </Route>
          <Route path={`${match.url}/orders`}>
            <Orders />
          </Route>
          <Route path={`${match.url}/`}>
            <Home />
          </Route>
        </Switch>
      </div>
      <SocialFooter />
      <FooterLinks />
      <Footer />
    </Suspense>
  );
};

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/404">
          <NotFound />
        </Route>
        <Route path="/:catalog_slug">
          <Site />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
